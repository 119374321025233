import React, { useState, useEffect, useRef, Fragment } from "react"
import { API_URL, getValFromLS } from "../../../utils/helpers"
import Pdf from "../../Pdf"
import Select from "../../../components/Select"
import Checkbox from "../../../components/Checkbox"
import Radios from "../../../components/Radios"
import Input from "../../../components/Input"
import Textarea from "../../../components/Textarea"
import Msg from "../../../components/Msg"
import Submit from "../../../components/Submit"
import Alert from "../../../components/Alert"
import * as styles from "./styles.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

const Two = () => {
  const [data, setData] = useState({
    months: [],
    days: [],
  })
  const [selected, setSelected] = useState({})
  const [names, setNames] = useState({
    year: "2021",
  })
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  })
  const [errors2, setErrors2] = useState([])
  const [msg2, setMsg2] = useState({})

  const formElement = useRef(null)
  const formElement2 = useRef(null)

  useEffect(() => {
    console.log("useEffect called")

    const init = async () => {
      const url = new URL(`${API_URL}/auth/adminarchiveInit`)
      const params = {
        token: getValFromLS("etfsrAdminToken"),
      }
      url.search = new URLSearchParams(params)

      try {
        const response = await fetch(url, {
          method: "GET",
          cache: "no-store",
        })
        const json = await response.json()
        if (json) {
          setData({ ...json })
          setAlert({})
        }
      } catch (error) {
        setAlert({
          type: "error",
          text: "An error has occurred.",
        })
      }
    }

    init()
  }, [])

  const handleUpdate = (name, value) => {
    if (name === "delete") {
      setNames(names => ({ ...names, [name]: !value }))
    } else {
      setNames(names => ({ ...names, [name]: value ? value : "" }))
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/auth/adminarchive`)
    let data = new FormData()
    Object.entries(names).forEach(([key, value]) => {
      data.append(key, value)
    })
    data.append("token", getValFromLS("etfsrAdminToken"))

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      handleResponse(json, null)
    } catch (error) {
      handleResponse(null, error)
    }
  }

  const handleResponse = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        setSelected({ ...json })
        setNames(names => ({
          ...names,
          date: json.date,
          notes: json.notes,
        }))
        setMsg({ type: "notify", text: json.text })
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } else {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  const handleSubmit2 = async e => {
    e.preventDefault()
    setErrors2([])
    setMsg2({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/auth/adminupload`)
    let data = new FormData()
    Object.entries(names).forEach(([key, value]) => {
      if (key === "delete" || key === "nosend") {
        data.append(key, value ? "1" : "0")
      } else {
        data.append(key, value)
      }
    })
    data.append("token", getValFromLS("etfsrAdminToken"))

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      handleResponse2(json, null)
    } catch (error) {
      handleResponse2(null, error)
    }
  }

  const handleResponse2 = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        setSelected({})
        setNames(names => ({
          ...names,
          date: "",
          notes: "",
        }))
        setMsg({ type: "success", text: json.text })
      } else {
        setErrors2(json.fields)
        setMsg2({
          type: "error",
          text: json.text,
        })
      }
    } else {
      setMsg2({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <section className={styles.two}>
      {alert && alert.type ? (
        <Alert data={alert} />
      ) : (
        <Fragment>
          <form
            method="post"
            action="/"
            onSubmit={e => handleSubmit(e)}
            ref={formElement}
          >
            <p>Use the form below to add/edit a report from the archive.</p>

            <div className="three">
              <Select
                name="month"
                label="Select Month"
                reqd={true}
                autocomplete="off"
                options={data.months.map(item => {
                  return {
                    value: item.value,
                    name: item.name,
                  }
                })}
                update={handleUpdate}
                errors={errors}
              />

              <Select
                name="day"
                label="Select Day"
                reqd={true}
                autocomplete="off"
                options={data.days.map(item => {
                  return {
                    value: item.value,
                    name: item.name,
                  }
                })}
                update={handleUpdate}
                errors={errors}
              />

              <Input
                name="year"
                label="Year"
                reqd={true}
                autocomplete="off"
                update={handleUpdate}
                errors={errors}
                value="2021"
              />
            </div>

            <div className="msgSubmit">
              {msg.type && <Msg data={msg} />}

              <div className={msg.type === "working" ? "hidden" : ""}>
                <Submit name="Submit Date" icon={faChevronCircleRight} />
              </div>
            </div>
          </form>

          {selected.resp === 1 && (
            <form
              method="post"
              action="/"
              onSubmit={e => handleSubmit2(e)}
              ref={formElement2}
            >
              <Pdf token={getValFromLS("etfsrAdminToken")} data={selected} />

              {selected.pdf && (
                <div className="one">
                  <Checkbox
                    name="delete"
                    label="Check to delete PDF (you can also replace it by uploading a new one)"
                    reqd={false}
                    click={handleUpdate}
                    checked={names.delete || false}
                  />
                </div>
              )}
              <div className={names.delete ? "two hidden" : "two"}>
                <Input
                  type="file"
                  name="file"
                  label="Select PDF"
                  reqd={true}
                  autocomplete="off"
                  update={handleUpdate}
                  errors={errors}
                />

                <div className="empty" />
              </div>

              <div className={names.delete ? "one hidden" : "one"}>
                <Textarea
                  name="notes"
                  label="Notes (include any optional notes to display along with report)"
                  reqd={false}
                  value={names.notes}
                  autocomplete="off"
                  update={handleUpdate}
                  errors={errors}
                />
              </div>

              <div className={names.delete ? "one hidden" : "one"}>
                <Radios
                  name="notify"
                  label="Indicate whether to send an email notification to subscribers after this update"
                  reqd={true}
                  click={handleUpdate}
                  data={[
                    {
                      value: "yes",
                      name: "Send ",
                      price: "",
                      amt: "",
                      checked: names.notify === "yes" ? true : false,
                    },
                    {
                      value: "no",
                      name: "Do Not Send",
                      price: "",
                      amt: "",
                      checked: names.notify === "no" ? true : false,
                    },
                  ]}
                />
              </div>

              <div className="msgSubmit">
                {msg2.type && <Msg data={msg2} />}

                <div className={msg2.type === "working" ? "hidden" : ""}>
                  <Submit
                    name={
                      names.delete ? "Delete Report" : "Upload & Publish Report"
                    }
                    icon={faChevronCircleRight}
                  />
                </div>
              </div>
            </form>
          )}
        </Fragment>
      )}
    </section>
  )
}
export default Two
