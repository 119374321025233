import React from "react"
import * as styles from "./styles.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle, faCheckCircle } from "@fortawesome/pro-light-svg-icons"

const Radios = ({ name, label, reqd, click, data, note = "" }) => {
  return (
    <div className={styles.radios}>
      <label htmlFor={name}>
        {`${label}`} {reqd ? <span className="error">*</span> : null}
      </label>
      <div>
        {data.map(item => {
          return item.price ? (
            <button
              type="button"
              key={item.value}
              onClick={e => click(name, item.value)}
            >
              <div className={styles.grid}>
                <FontAwesomeIcon
                  icon={item.checked ? faCheckCircle : faCircle}
                />
                <span>{item.name}</span>
                <span>{item.price}</span>
              </div>
            </button>
          ) : (
            <button
              type="button"
              key={item.value}
              onClick={e => click(name, item.value)}
            >
              <div className={styles.grid}>
                <FontAwesomeIcon
                  icon={item.checked ? faCheckCircle : faCircle}
                />
                <span>{item.name}</span>
              </div>
            </button>
          )
        })}
      </div>
      <small className={note ? "" : "hidden"}>{note}</small>
    </div>
  )
}
export default Radios
