import React from "react"
import H1 from "../../components/H1"
import * as styles from "./styles.module.css"

const One = () => {
  return (
    <section className={styles.two}>
      <H1 text="Administration Area"></H1>
    </section>
  )
}

export default One
