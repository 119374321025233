import React, { Fragment } from "react"
//import { navigate } from "gatsby"
//import { getValFromLS } from "../../utils/helpers"
import Two from "./Two"

/*
  TODO
  add redirect to /admin if already have etfsrAdminToken

  getValFromLS("etfsrAdminToken") &&
  typeof window !== `undefined` &&

  and check if token is valid;
    navigate("/admin")
*/

const Signin = () => {
  return (
    <Fragment>
      <Two />
    </Fragment>
  )
}

export default Signin
