import React, { useState, useEffect, useRef } from "react"
import { API_URL } from "../utils/helpers"
import * as styles from "./Home/Two/styles.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from "@fortawesome/pro-light-svg-icons"

const Pdf = ({ token, data }) => {
  return (
    <div
      className={
        data.pdf
          ? `${styles.pdfContainer} ${styles.success}`
          : `${styles.pdfContainer} ${styles.working}`
      }
    >
      <FontAwesomeIcon icon={faFilePdf} size="3x" />

      {data.pdf ? (
        <div className={styles.right}>
          <a
            href={`${API_URL}/auth/pdf?token=${token}&date=${data.date}`}
            className="icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            {`${data.date}.pdf`}
          </a>
        </div>
      ) : (
        <div className={styles.right}>
          <span>No PDF uploaded</span>
        </div>
      )}
    </div>
  )
}

export default Pdf
