import React from "react"
import { navigate } from "gatsby"
import { getValFromLS } from "../../utils/helpers"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (
    !getValFromLS("etfsrAdminToken") &&
    typeof window !== `undefined` &&
    location.pathname !== `/admin/signin`
  ) {
    navigate("/admin/signin")
    return null
  }
  return <Component {...rest} />
}
export default PrivateRoute
