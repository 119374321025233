import React, { useState, useEffect, useRef } from "react"
import { API_URL, getValFromLS } from "../../../utils/helpers"
import Select from "../../../components/Select"
import Checkbox from "../../../components/Checkbox"
import Radios from "../../../components/Radios"
import Input from "../../../components/Input"
import Textarea from "../../../components/Textarea"
import Msg from "../../../components/Msg"
import Submit from "../../../components/Submit"
import Alert from "../../../components/Alert"
import * as styles from "./styles.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFilePdf,
  faChevronCircleRight,
} from "@fortawesome/pro-light-svg-icons"

const Pdf = ({ token, data }) => {
  return (
    <div
      className={
        data.pdf
          ? `${styles.pdfContainer} ${styles.success}`
          : `${styles.pdfContainer} ${styles.working}`
      }
    >
      <FontAwesomeIcon icon={faFilePdf} size="3x" />

      {data.pdf ? (
        <div className={styles.right}>
          <a
            href={`${API_URL}/auth/pdf?token=${token}&date=${data.date}`}
            className="icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            {`${data.date}.pdf`}
          </a>
        </div>
      ) : (
        <div className={styles.right}>
          <span>No PDF uploaded</span>
        </div>
      )}
    </div>
  )
}

const Two = () => {
  const [data, setData] = useState([])
  const [selected, setSelected] = useState({})
  const [names, setNames] = useState({})
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  })

  const formElement = useRef(null)

  useEffect(() => {
    console.log("useEffect called")

    const init = async () => {
      const url = new URL(`${API_URL}/auth/adminuploadInit`)
      const params = {
        token: getValFromLS("etfsrAdminToken"),
      }
      url.search = new URLSearchParams(params)

      try {
        const response = await fetch(url, {
          method: "GET",
          cache: "no-store",
        })
        const json = await response.json()
        if (json) {
          setData(json.data)
          setAlert({
            type: "notice",
            text: "Please select a day to continue.",
          })
        }
      } catch (error) {
        setAlert({
          type: "error",
          text: "An error has occurred.",
        })
      }
    }

    init()
  }, [])

  const handleUpdate = (name, value) => {
    if (name === "date") {
      const index = parseInt(value, 10)
      if (index) {
        setSelected(data[index])
        setNames(names => ({
          ...names,
          date: data[index].date,
          notes: data[index].notes,
        }))
        formElement.current.file.value = ""
        formElement.current.notes.value = data[index].notes
        setMsg({})
      } else {
        setSelected({})
        setNames(names => ({ ...names, date: "" }))
      }
      /* reset alert b/c of success mesage */
      setAlert({
        type: "notice",
        text: "Please select a day to continue.",
      })
    } else if (name === "delete") {
      setNames(names => ({ ...names, [name]: !value }))
    } else {
      setNames(names => ({ ...names, [name]: value ? value : "" }))
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/auth/adminupload`)
    let data = new FormData()
    Object.entries(names).forEach(([key, value]) => {
      if (key === "delete" || key === "nosend") {
        data.append(key, value ? "1" : "0")
      } else {
        data.append(key, value)
      }
    })
    data.append("token", getValFromLS("etfsrAdminToken"))

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      handleResponse(json, null)
    } catch (error) {
      handleResponse(null, error)
    }
  }

  const handleResponse = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        formElement.current.reset()
        formElement.current[0].selectedIndex = 0
        setNames({})
        setSelected({})
        setMsg({})
        setData(json.data)
        setAlert({
          type: "success",
          text: json.text,
        })
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } else {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <section className={styles.two}>
      {data.length === 0 ? (
        <Alert data={alert} />
      ) : (
        <form
          method="post"
          action="/"
          onSubmit={e => handleSubmit(e)}
          ref={formElement}
        >
          <p>
            Use this form to add/remove/modify a Report PDF for a specific day
            this week.
          </p>
          <div className="two">
            <Select
              name="date"
              label="Select Day"
              reqd={true}
              autocomplete="off"
              options={data.map((item, index) => {
                return {
                  value: index,
                  name: item.name,
                }
              })}
              update={handleUpdate}
              errors={errors}
            />

            <div className="empty" />
          </div>
          {!names.date && <Alert data={alert} />}
          {names.date && (
            <Pdf token={getValFromLS("etfsrAdminToken")} data={selected} />
          )}
          {selected.pdf && (
            <div className="one">
              <Checkbox
                name="delete"
                label="Check to delete PDF (you can also replace it by uploading a new one)"
                reqd={false}
                click={handleUpdate}
                checked={names.delete || false}
              />
            </div>
          )}
          <div className={!names.date || names.delete ? "two hidden" : "two"}>
            <Input
              type="file"
              name="file"
              label="Select PDF"
              reqd={true}
              autocomplete="off"
              update={handleUpdate}
              errors={errors}
            />

            <div className="empty" />
          </div>

          <div className={!names.date || names.delete ? "one hidden" : "one"}>
            <Textarea
              name="notes"
              label="Notes (include any optional notes to display along with report)"
              reqd={false}
              autocomplete="off"
              update={handleUpdate}
              errors={errors}
            />
          </div>

          <div className={!names.date || names.delete ? "one hidden" : "one"}>
            <Radios
              name="notify"
              label="Indicate whether to send an email notification to subscribers after this update"
              reqd={true}
              click={handleUpdate}
              data={[
                {
                  value: "yes",
                  name: "Send ",
                  price: "",
                  amt: "",
                  checked: names.notify === "yes" ? true : false,
                },
                {
                  value: "no",
                  name: "Do Not Send",
                  price: "",
                  amt: "",
                  checked: names.notify === "no" ? true : false,
                },
              ]}
            />
          </div>

          <div className={!names.date ? "msgSubmit hidden" : "msgSubmit"}>
            {msg.type && <Msg data={msg} />}

            <div className={msg.type === "working" ? "hidden" : ""}>
              <Submit
                name={
                  names.delete ? "Delete Report" : "Upload & Publish Report"
                }
                icon={faChevronCircleRight}
              />
            </div>
          </div>
        </form>
      )}
    </section>
  )
}
export default Two
