import React, { Fragment } from "react"
import { Router } from "@reach/router"
import Helmet from "../components/Helmet"
import PrivateRoute from "../components/PrivateRoute/admin" /* private route for admin area */
import Header from "../adminPage/Header" /* header for admin area */
import One from "../adminPage/One"
import Signin from "../adminPage/Signin"
import Home from "../adminPage/Home"
import Archive from "../adminPage/Archive"
import Signout from "../adminPage/Signout"

const AdminPage = () => (
  <Fragment>
    <Helmet title="Administration Area" descr="" admin={true} />
    <Header />
    <One />
    <Router>
      <Signin path="/admin/signin" />
      <PrivateRoute exact path="/admin" component={Home} />
      <PrivateRoute exact path="/admin/archive" component={Archive} />
      <PrivateRoute exact path="/admin/signout" component={Signout} />
    </Router>
  </Fragment>
)
export default AdminPage
