import React, { Fragment } from "react"
import Two from "./Two"

const Archive = () => {
  return (
    <Fragment>
      <Two />
    </Fragment>
  )
}

export default Archive
